import { Form, Select, Spin } from 'antd';
import ErrorAlert from '../../../../Error/ErrorAlert';

const { Option } = Select;

/**
 * Dropdown for selecting an AI model
 * @param {string} label - Label for the dropdown
 * @param {boolean} disabled - Whether the dropdown is disabled
 * @param {array} models - List of available models
 * @param {boolean} isLoading - Loading state for the dropdown
 * @param {boolean} isError - Error state for the dropdown
 * @param {object} error - Error object, if any
 * @param {function} onChange - Callback for dropdown value change
 */
function ModelSelector({
	label,
	disabled,
	models,
	isLoading,
	isError,
	error,
	onChange,
	selectedModel,
}) {
	const hasData = !isLoading && !isError;

	return (
		<Form.Item
			label={label}
			name="modelId"
			rules={[{ required: true, message: 'Please select a model!' }]}
		>
			<Select
				placeholder="Select a model"
				value={selectedModel?.id}
				aria-label="AI Models Dropdown"
				disabled={disabled}
				notFoundContent={
					isError ? <ErrorAlert error={error} /> : <Spin size="small" />
				}
				onChange={onChange}
			>
				{hasData &&
					models.map((model) => (
						<Option key={model.id} value={model.id}>
							{model.name}
						</Option>
					))}
			</Select>
		</Form.Item>
	);
}

export { ModelSelector };
