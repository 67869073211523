import React, { Fragment } from 'react';
import { Form, InputNumber, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';


const { Item } = Form;


const CompletionsInfo = () => {
  return (<Fragment>

    <Item
      label="Completions Per Task"
      name="completionsPerTask"
      initialValue={1}
      rules={[{
        required: true, message: 'Please set the number of completions required for each task!'
      }]}>
      <InputNumber min={1} />
    </Item>

    <Item
      label="Allow duplicate completions"
      name="allowDuplicateCompletions"
      initialValue={false}
      valuePropName="checked"
    >
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />} />
    </Item>

    <Item
      label="Allow completion arbitration"
      name="allowArbitration"
      initialValue={false}
      valuePropName="checked"
      tooltip={<span>Completions will automatically be created for dissimilar results.<br />
        <b>Note: Completions are not generated all at once when the project is created; they are only created in cases of conflicting results.</b></span>}
      dependencies={['completionsPerTask']}
      rules={[({ getFieldValue }) => ({
        validator(_, value) {
          if (getFieldValue('completionsPerTask') > 2 || !value) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('Completion arbitration can only be enabled when completions per task is greater than 2!'));
        }
      })]}>
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />} />
    </Item>


    <Item
      label="Force different completions by different users"
      name="diffUsersToTaskCompletions"
      initialValue={false}
      valuePropName="checked">
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />} />
    </Item>

    <Item
      label="Show previous completions for the same task"
      name="showPreviousCompletions"
      initialValue={false}
      valuePropName="checked">
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />} />
    </Item>

  </Fragment>);
};

export default CompletionsInfo;
