import { Switch } from 'antd';
import styled from 'styled-components';

const Label = styled.label`
	font-size: large;
`;

const ToggleWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
`;

/**
 * A toggle switch with a label
 * @param {string} label - Label for the switch
 * @param {boolean} checked - Whether the switch is on
 * @param {function} onChange - Callback for switch state change
 */
function ToggleSwitch({ label, checked, onChange }) {
	return (
		<ToggleWrapper>
			<Switch checked={checked} onChange={onChange} />
			<Label>{label}</Label>
		</ToggleWrapper>
	);
}

export { ToggleSwitch };
