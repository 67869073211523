import { useMutation } from '@tanstack/react-query';

import { aiAssistedAPI } from '../api/aiAsissted';

const createMutationKeyGeneratePredictions = () => [
	'model/generate-predictions',
];

export const useGeneratePredictions = ({ onError }) => {
	const mutation = useMutation({
		mutationFn: aiAssistedAPI.generatePredictions,
		mutationKey: createMutationKeyGeneratePredictions(),
		onError,
	});

	return {
		...mutation,
		generatePredictions: mutation.mutate,
		generatePredictionsError: mutation.error,
		isGeneratePredictionsError: mutation.isError,
		backgroundTaskID: mutation.data,
	};
};
