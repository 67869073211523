import { Button, Card, Space } from 'antd';
import {
	CheckCircleOutlined,
	MailOutlined,
	MobileOutlined,
} from '@ant-design/icons';
import TwoFactorAuthPhoneNumber from './TwoFactorAuthPhoneNumber';
import TwoFactorAuthTOTP from './TwoFactorAuthTOTP';
import ErrorAlert from '../Error/ErrorAlert';
import React, { useEffect } from 'react';
import { CardTitle } from './Login/Login';
import { useMediaQuery } from 'react-responsive';
import { useAuthContext } from '../../contexts/AuthContext';
import TwoFactorAuthTOTPVerification from './TwoFactorAuthTOTPVerification';
import TwoFactorAuthSMSVerification from './TwoFactorAuthSMSVerification';

const TwoFactorAuthSetup = () => {
	const [isTOTP, setIsTOTP] = React.useState(false);
	const [isSMS, setIsSMS] = React.useState(false);
	const [isSMSEnrolled, setIsSMSEnrolled] = React.useState(false);
	const [isTOTPEnrolled, setIsTOTPEnrolled] = React.useState(false);
	const [hasOther2FAOption, setHasOther2FAOption] = React.useState(false);
	const [displayVerifyDeviceView, setDisplayVerifyDeviceView] =
		React.useState(false);
	const [phoneNumber, setPhoneNumber] = React.useState();

	const {
		sendSmsVerificationCode,
		verifySmsCode,
		verifyTotpCode,
		activateUserPhoneNumber,
		verifyUserPhoneNumber,
		twoFactorAuthEnrolledFactors,
		selectedTwoFactorAuthMethod,
		setSelected2FAMethod,
		secondFactorPhoneNumber,
		clearError,
		quit2FA,
		isSendingSms,

		error,
	} = useAuthContext();

	const isTabletOrMobile = useMediaQuery({ maxWidth: 480 });

	const cardStyle = {
		width: isTabletOrMobile ? '100%' : '450px',
		marginBottom: '20px',
	};
	// Handling the enrolled 2FA methods.
	useEffect(() => {
		if (twoFactorAuthEnrolledFactors.length === 0) {
			return;
		}
		twoFactorAuthEnrolledFactors.forEach((method) => {
			if (method === 'phone') {
				setIsSMSEnrolled(true);
				if (secondFactorPhoneNumber) {
					setPhoneNumber(secondFactorPhoneNumber);
				}
			}
			if (method === 'totp') {
				setIsTOTPEnrolled(true);
			}
		});

		setHasOther2FAOption(twoFactorAuthEnrolledFactors.length > 1);
	}, [twoFactorAuthEnrolledFactors, phoneNumber, secondFactorPhoneNumber]);

	// Handling the selected 2FA method.
	useEffect(() => {
		if (selectedTwoFactorAuthMethod === 'phone') {
			setIsSMS(true);
			setIsTOTP(false);
		} else if (selectedTwoFactorAuthMethod === 'totp') {
			setIsTOTP(true	);
			setIsSMS(false);
		} else {
			setIsTOTP(false);
			setIsSMS(false);
		}
	}, [selectedTwoFactorAuthMethod]);

	const goToOther2FAOption = () => {
		setSelected2FAMethod(null);
		// Clear the error before going to the other 2FA option.
		clearError();
	};

	const handleGoBackToSignIn = () => {
		clearError();
		quit2FA();
	};

	const handle2FASelection = (method) => {
		switch (method) {
			case 'phone':
				setSelected2FAMethod('phone');
				break;
			case 'totp':
				setSelected2FAMethod('totp');
				break;
			default:
				console.error('Invalid 2FA method selected!');
		}
	};

	// 2FA-SignIn: Send the verification code to the user's registered phone number.
	const handle2FALoginResendCode = () => {
		sendSmsVerificationCode();
	};

	// 2FA-SignIn: Verify the code entered to be the one sent in the sms.
	const handleSMSVerification = (verificationCode) => {
		verifySmsCode(verificationCode);
	};

	const handleTOTPVerification = (verificationCode) => {
		verifyTotpCode(verificationCode);
	};
	// 2FA-Enrollment: Verify enetered phone number by sending and sms.
	const handle2FAActivationSubmitPhoneNumber = async (phoneNumber) => {
		if (phoneNumber) {
			// Keeping the phone number in the state for later trials to resend the code.
			setPhoneNumber(phoneNumber);

			// Sending the verification sms to the user to verify the entered phone number.
			activateUserPhoneNumber(phoneNumber)
				.then(() => setDisplayVerifyDeviceView(true))
				.catch((e) => {
					// The recaptcha isn't verified or the activation of the phone number
					// has failed and reported in the error of the context, so we do nothing.
					setDisplayVerifyDeviceView(true);
				});
		} else {
			console.log('Phone number is not available!');
		}
	};

	// 2FA-Enrollment: Verify the code entered to be the one sent in the sms.
	const handle2FAActivationVerifyPhoneNumber = (verificationCode) => {
		verifyUserPhoneNumber(verificationCode);
	};

	return (
		<Space align="center" direction="vertical">
			<Card
				style={cardStyle}
				title={<CardTitle text="Second Authentication Factor Is Required!" />}
			>
				{!isTOTP && !isSMS && (
					<>
						<p>
							Choose a second factor authentication method to secure your
							account...
						</p>
						<Space direction="horizontal" style={{ marginBottom: '20px' }}>
							<Button
								type="default"
								onClick={() => handle2FASelection('phone')}
							>
								<MailOutlined /> SMS
								<CheckCircleOutlined
									style={{ color: 'green' }}
									hidden={!isSMSEnrolled}
								/>
							</Button>

							<Button type="default" onClick={() => handle2FASelection('totp')}>
								<MobileOutlined /> Authenticator App
								<CheckCircleOutlined
									style={{ color: 'green' }}
									hidden={!isTOTPEnrolled}
								/>
							</Button>
						</Space>
					</>
				)}

				{/* TOTP Setup */}
				{isTOTP && !isTOTPEnrolled && (
					<TwoFactorAuthTOTP goBackToSignIn={handleGoBackToSignIn} />
				)}
				{/* TOTP Verification */}
				{isTOTP && isTOTPEnrolled && (
					<Space align="center" direction="vertical">
						<TwoFactorAuthTOTPVerification
							verifyCode={handleTOTPVerification}
							hasOther2FAOption={hasOther2FAOption}
							goToOther2FAOption={goToOther2FAOption}
							goBackToSignIn={handleGoBackToSignIn}
							isSetup={false}
						/>
					</Space>
				)}

				{/* SMS Setup */}
				{isSMS &&
					!isSMSEnrolled &&
					!phoneNumber &&
					!displayVerifyDeviceView && (
						<TwoFactorAuthPhoneNumber
							submitPhoneNumber={handle2FAActivationSubmitPhoneNumber}
							goBackToSignIn={handleGoBackToSignIn}
							goToOther2FAOption={goToOther2FAOption}
						/>
					)}

				{/* SMS Setup Verification */}
				{isSMS && !isSMSEnrolled && displayVerifyDeviceView && (
					<TwoFactorAuthSMSVerification
						resendCode={handle2FALoginResendCode}
						verifyCode={handle2FAActivationVerifyPhoneNumber}
						goToOther2FAOption={goToOther2FAOption}
						hasOther2FAOption={hasOther2FAOption}
						goBackToSignIn={handleGoBackToSignIn}
					/>
				)}

				{/*	SMS Verification */}
				{isSMS && isSMSEnrolled && (
					<TwoFactorAuthSMSVerification
						resendCode={handle2FALoginResendCode}
						verifyCode={handleSMSVerification}
						goToOther2FAOption={goToOther2FAOption}
						hasOther2FAOption={hasOther2FAOption}
						goBackToSignIn={handleGoBackToSignIn}
						phoneNumber={phoneNumber}
					/>
				)}
			</Card>
			<ErrorAlert error={error} />
		</Space>
	);
};

export default TwoFactorAuthSetup;
