import { Form } from 'antd';
import ReactJson from 'react-json-view';

/**
 * Displays and allows editing of a model's configuration
 * @param {boolean} disabled - Whether the textarea is disabled
 * @param {object} configuration - Selected model configuration object
 * @param {function} onChange - Callback for configuration changes
 */
function ModelConfiguration({ disabled, configuration, onChange }) {
	const handleConfigChange = (edit) => {
		const updatedConfig = edit.updated_src;
		onChange?.(updatedConfig);
	};

	return (
		<Form.Item
			label="Model Configuration"
			name="modelConfiguration"
			valuePropName="modelConfiguration"
		>
			<ReactJson
				src={configuration}
				enableClipboard={false}
				displayDataTypes={false}
				displayObjectSize={false}
				name={null}
				theme="rjv-default"
				collapseStringsAfterLength={20}
				style={{
					opacity: disabled ? 0.5 : 1,
					pointerEvents: disabled ? 'none' : 'auto',
				}}
				onEdit={handleConfigChange}
				onAdd={handleConfigChange}
				onDelete={handleConfigChange}
			/>
		</Form.Item>
	);
}

export { ModelConfiguration };
