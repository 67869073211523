import { useState } from 'react';
import styled from 'styled-components';
import { Form, Button } from 'antd';

import { usePMProjectSingleContext } from '../../../../contexts/PMPrjSingleContext';
import { formatError } from '../../../../contexts/ResponseErrorFormatter';

import ErrorModal from '../../../Error/ErrorModal';

import { ModelConfigurationForm } from './components/ModelConfigurationForm';
import { useGeneratePredictions } from './mutatuion/useGeneratePredictions';

const GridLayout = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-row-gap: 4rem;
`;

function AiAssisted() {
	const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [
		modelsConfigurationValidationStatus,
		setModelsConfigurationValidationStatus,
	] = useState({});

	const { selectedProject } = usePMProjectSingleContext();

	const showModal = () => setShowErrorModal(true);
	const handleCancel = () => setShowErrorModal(false);

	const {
		generatePredictions,
		backgroundTaskID,
		generatePredictionsError,
		isGeneratePredictionsError,
	} = useGeneratePredictions({
		onError: showModal,
	});

	const handleFormChange = (changedFields, allForms) => {
		const allFormsValues = Object.values(allForms?.forms).map((form) =>
			form.getFieldsValue(),
		);

		const isAnyToggleActive = allFormsValues.some((formValues) =>
			Object.entries(formValues).some(
				([key, value]) => key.endsWith('.isModelActive') && value === true,
			),
		);

		setIsSubmitEnabled(isAnyToggleActive);
	};

	const handleOnValidateModelConfiguration = (formName, isValid) => {
		setModelsConfigurationValidationStatus((prev) => ({
			...prev,
			[formName]: isValid,
		}));
	};

	const handleOnFinish = (name, { values, forms }) => {
		let chosenModels = [];
		Object.entries(forms).forEach(([formName, formAttributes]) => {
			const formData = formAttributes.getFieldsValue();
			const model = {
				id: formData.modelId,
				model_options: JSON.parse(formData.modelConfiguration),
			};
			chosenModels.push(model);
		});

		generatePredictions({
			payload: { models: chosenModels },
			projectId: selectedProject.id,
		});
	};

	const isAllModelsConfigurationsValid = Object.values(
		modelsConfigurationValidationStatus,
	).every((status) => status === true);

	const isGenerateEnabled = isSubmitEnabled && isAllModelsConfigurationsValid;

	return (
		<Form.Provider
			onFormFinish={handleOnFinish}
			onFormChange={handleFormChange}
		>
			{isGeneratePredictionsError && (
				<ErrorModal
					error={formatError(
						generatePredictionsError,
						'Failed To Generate Predictions!',
					)}
					onDone={handleCancel}
					visible={showErrorModal}
				/>
			)}

			<Form>
				<Form.Item style={{ textAlign: 'right' }}>
					<Button
						type="primary"
						htmlType="submit"
						disabled={!isGenerateEnabled}
					>
						Generate
					</Button>
				</Form.Item>

				<GridLayout>
					<ModelConfigurationForm
						label="Speech To Text"
						onValidateConfiguration={handleOnValidateModelConfiguration}
					/>
					<ModelConfigurationForm
						label="Speaker Diarization"
						onValidateConfiguration={handleOnValidateModelConfiguration}
					/>
				</GridLayout>
			</Form>
		</Form.Provider>
	);
}

export { AiAssisted };
