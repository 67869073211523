import { useQuery } from '@tanstack/react-query';
import { aiAssistedAPI } from '../api/aiAsissted';

export function useGetAIModels() {
	const queryKey = ['ai-models'];

	const result = useQuery({
		queryKey,
		queryFn: aiAssistedAPI.getAIModels,
		refetchOnWindowFocus: false,
		staleTime: 5 * 60 * 1000,
		cacheTime: 10 * 60 * 1000,
	});

	return result;
}
