import { useMutation } from '@tanstack/react-query';

import { aiAssistedAPI } from '../api/aiAsissted';

const createMutationKeyValidateModelConfiguration = () => [
	'model/validate-model-configuration',
];

export const useValidateModelConfiguration = ({ onError }) => {
	const mutation = useMutation({
		mutationFn: aiAssistedAPI.validateModelConfiguration,
		mutationKey: createMutationKeyValidateModelConfiguration(),
		onError,
	});

	return {
		...mutation,
		validateConfiguration: mutation.mutateAsync,
		validateConfigurationError: mutation.error,
		isValidateConfigurationError: mutation.isError,
	};
};
