import { useState } from 'react';
import styled from 'styled-components';
import { Form } from 'antd';

import { ToggleSwitch } from './ToggleSwitch';
import { ModelSelector } from './ModelSelector';
import { ModelConfiguration } from './ModelConfiguration';

import { useGetAIModels } from '../query/useGetAIModels';
import { useValidateModelConfiguration } from '../mutatuion/useValidateModelConfiguration';

import { formatError } from '../../../../../contexts/ResponseErrorFormatter';
import ErrorModal from '../../../../Error/ErrorModal';

const FormContainer = styled(Form)`
	display: flex;
	flex-direction: column;
	gap: 4rem;
	padding: 2rem 0 0 2rem;
`;

const layout = {
	labelCol: {
		span: 4,
	},
	wrapperCol: {
		span: 8,
		offset: 1,
	},
};

function ModelConfigurationForm({ label, onValidateConfiguration }) {
	const FormContainerName = label.split(' ').join('').toLowerCase();

	const [isModelActive, setIsModelActive] = useState(false);
	const [selectedModel, setSelectedModel] = useState(null);
	const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
	const [form] = Form.useForm();

	const showModal = () => setIsErrorModalOpen(true);
	const handleCancel = () => setIsErrorModalOpen(false);

	const { data, isLoading, isError, error } = useGetAIModels();
	const {
		validateConfiguration,
		validateConfigurationError,
		isValidateConfigurationError,
	} = useValidateModelConfiguration({ onError: showModal });

	const handleToggle = (checked) => {
		setIsModelActive(checked);
		form.setFieldsValue({ [`${FormContainerName}.isModelActive`]: checked });
	};

	const handleModelChange = (selectedModelId) => {
		const selectedModel = data?.integrations.find(
			(model) => model.id === selectedModelId,
		);
		setSelectedModel(selectedModel);

		form.setFieldsValue({
			modelId: selectedModelId,
			modelConfiguration: JSON.stringify(selectedModel?.config || {}),
		});
	};

	const handleConfigChange = async (newConfig) => {
		if (!selectedModel) return;

		try {
			await validateConfiguration({
				configuration: newConfig,
				modelId: selectedModel.id,
				typeId: selectedModel.type_id,
			});

			form.setFieldsValue({
				modelConfiguration: JSON.stringify(newConfig),
			});
			onValidateConfiguration(FormContainerName, true);
		} catch (error) {
			console.error('Model Validation failed:', error);
			onValidateConfiguration(FormContainerName, false);
		}
	};

	return (
		<>
			{isValidateConfigurationError && (
				<ErrorModal
					error={formatError(
						validateConfigurationError,
						'Failed To Validate Model Configuration!',
					)}
					onDone={handleCancel}
					visible={isErrorModalOpen}
				/>
			)}

			<FormContainer
				{...layout}
				form={form}
				autoComplete="off"
				initialValues={{
					modelConfiguration: '{}',
					modelId: undefined,
					[`${FormContainerName}.isModelActive`]: false,
				}}
				name={FormContainerName}
			>
				<Form.Item name={`${FormContainerName}.isModelActive`}>
					<ToggleSwitch
						label={label}
						checked={isModelActive}
						onChange={handleToggle}
					/>
				</Form.Item>

				<ModelSelector
					label="Available Models"
					disabled={!isModelActive}
					models={data?.integrations || []}
					isLoading={isLoading}
					isError={isError}
					error={error}
					onChange={handleModelChange}
					selectedModel={selectedModel}
				/>

				<ModelConfiguration
					disabled={!isModelActive}
					onChange={handleConfigChange}
					configuration={selectedModel?.config || {}}
				/>
			</FormContainer>
		</>
	);
}

export { ModelConfigurationForm };
